<template>
  <div class="side">
    <el-menu>
      <div class="title_logo">
        <img class="login_logo" @click="$router.push('/')" src="../../assets/img/logo.png" alt="">
      </div>

      <router-link :to="{ path: '/' }">
        <el-menu-item index="1" :class="index_active==1?'link_active':'' ">
          <img class="left_icon" src="../../assets/img/side_icon1.png" alt="">
          <span slot="title">{{$t('main.text6')}}</span>
        </el-menu-item>
      </router-link>

      <router-link :to="{ path: '/candidate' }">
        <el-menu-item index="2" :class="index_active==2?'link_active':'' ">
          <img class="left_icon" src="../../assets/img/side_icon2.png" alt="">
          <span slot="title">{{$t('main.text7')}}</span>
        </el-menu-item>
      </router-link>

      <router-link :to="{ path: '/account_manage' }" v-if="role!=5">
        <el-menu-item index="3" :class="index_active==3?'link_active':'' ">
          <img class="left_icon" src="../../assets/img/side_icon3.png" alt="">
          <span slot="title">{{$t('main.text8')}}</span>
        </el-menu-item>
      </router-link>

      <router-link :to="{ path: '/setting' }">
        <el-menu-item index="4" :class="index_active==4?'link_active':'' ">
          <img class="left_icon" src="../../assets/img/side_icon4.png" alt="">
          <span slot="title">{{$t('main.text9')}}</span>
        </el-menu-item>
      </router-link>
      
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      index_active:1,
      role: "", //1管理⽤户；5⾯试官⽤户； ⾯试官⽤户没有账号管理
    }
  },
  watch:{
    $route(){
      this.set_active();
    }
  },
  mounted() {
    this.role = sessionStorage.getItem("role")||'';
    this.set_active();
  },
  methods:{
    set_active(){
      if(this.$route.path=='/'){
        this.index_active = 1;
      }else if(this.$route.path.indexOf("candidate")!=-1){
        if(this.$route.query.form_type=="order"){
          this.index_active = 1;
        }else{
          this.index_active = 2;
        }
      }else if(this.$route.path.indexOf("account_manage")!=-1){
        this.index_active = 3;
      }else if(this.$route.path.indexOf("setting")!=-1){
        this.index_active = 4;
      }
      // console.log(this.$route.query.form_type);
    }
  },
  


}
</script>

<style scoped>
.side{
  background: #1F2F54;
  height: 100%;
}
.title_logo{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
}
.el-menu {
  background: initial;
  border: none;
}
.el-menu-item:focus, .el-menu-item:hover {
  outline: 0;
  background-color: #192545;
}
.el-menu-item.is-active {
  color: #fff;
}
.link_active{
  color: #fff !important;
  background-color: #192545;
}
.el-menu-item {
  color: rgba(255, 255, 255, 0.7);
}
.left_icon{
  width: 20px;
  margin-right: 15px;
  vertical-align: middle;
}
.login_logo{
  width: 80%;
  cursor: pointer;
}
</style>
